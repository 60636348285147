<template>
  <div id="Simulator">
    <div>
      <ul
        v-if="
          simulationForm.simulationType === 'COMPLEX' ||
          simulationType === 'COMPLEX'
        "
        class="gigwhat-navTabs"
      >
        <template v-for="(step, index) in complexSimulation">
          <li
            class="navTab_item"
            :class="[
              (isEdit && currentStep === step.key - 1) || (!isEdit && currentStep === step.key) ? 'active-navTab' : '',
              (isEdit && currentStep > step.key - 1) || (!isEdit && currentStep > step.key) ? 'done-stepTab' : '',
            ]"
            :key="index"
          >
            <button  :id="'step-'+step.key">
              <span v-if="(isEdit && currentStep > step.key - 1) || (!isEdit && currentStep > step.key)" class="navTab-item_done">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.782"
                  height="7.769"
                  viewBox="0 0 10.782 7.769"
                >
                  <path
                    id="Tracé_2381"
                    data-name="Tracé 2381"
                    d="M67.246,104.023a1.113,1.113,0,0,0,.79-.327l5.534-5.534a1.117,1.117,0,0,0-1.58-1.58l-4.744,4.744L65.023,99.1a1.117,1.117,0,1,0-1.58,1.58l3.013,3.013a1.114,1.114,0,0,0,.79.327Z"
                    transform="translate(-63.115 -96.254)"
                    fill="#808fa3"
                  />
                </svg>
              </span>
              <span v-else class="navTab-item_number">{{ index + 1 }}</span>
              <span class="navTab-item_label">{{ step.name }}</span>
              <b-tooltip :target="'step-'+step.key" placement="bottom" triggers="focus">
                {{ step.name }}
              </b-tooltip>
            </button>
          </li>
        </template>
      </ul>

      <ul v-else class="gigwhat-navTabs">
        <template v-for="(step, index) in simpleSimulation">
          <li
            class="navTab_item"
            :class="[
              (isEdit && currentStep === step.key - 1) || (!isEdit && currentStep === step.key) ? 'active-navTab' : '',
              (isEdit && currentStep > step.key - 1) || (!isEdit && currentStep > step.key) ? 'done-stepTab' : '',
            ]"
            :key="index"
          >
            <button :id="'step-'+step.key">
              <span v-if="(isEdit && currentStep > step.key - 1) || (!isEdit && currentStep > step.key)" class="navTab-item_done">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.782"
                  height="7.769"
                  viewBox="0 0 10.782 7.769"
                >
                  <path
                    id="Tracé_2381"
                    data-name="Tracé 2381"
                    d="M67.246,104.023a1.113,1.113,0,0,0,.79-.327l5.534-5.534a1.117,1.117,0,0,0-1.58-1.58l-4.744,4.744L65.023,99.1a1.117,1.117,0,1,0-1.58,1.58l3.013,3.013a1.114,1.114,0,0,0,.79.327Z"
                    transform="translate(-63.115 -96.254)"
                    fill="#808fa3"
                  />
                </svg>
              </span>
              <span v-else class="navTab-item_number">{{ index + 1 }}</span>
              <span class="navTab-item_label">{{ step.name }}</span>
              <b-tooltip :target="'step-'+step.key" placement="bottom" triggers="focus">
                {{ step.name }}
              </b-tooltip>
            </button>
          </li>
        </template>
      </ul>
      <simulation-wizzard
        v-if="isEdit"
        :currentStep="currentStep + 1"
      ></simulation-wizzard>
      <simulation-wizzard
        v-else
        :currentStep="currentStep"
      ></simulation-wizzard>

      <div
        v-if="showNav"
        class="
          giga-simulator_footer
          giga-document_footer
          giga-flex giga-flex_end
        "
      >
        <div
          v-if="currentStep > 1 && currentStep !== steps.length"
          class="giga-button"
        >
          <button @click="goPrev" class="giga-blue_outlined_button no-icon">
            <span>Précédent</span>
          </button>
        </div>
        <div class="giga-button">
          <button @click="goNext" :disabled="isInvalid" class="giga-green_button no-icon">
            <span v-if="isNextFinish">Suivant</span>
            <span v-else>Suivant</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import SimulationWizzard from "./SimulationWizzard.vue";
import {workService} from "../Works/services";
import {required, requiredIf} from "vuelidate/lib/validators";
import moment from "moment";
import {map} from "lodash";
import eventBus from "@/shared/event.bus";
import {estimationService, simulationService} from "./services";
import store from "@/store";
import {simulation} from "@/store/simulation.module";
import Default from "@/layouts/wrappers/default.vue";
import {constants} from "@/shared/constants";

export default {
  name: "Simulator",
  components: { SimulationWizzard },
  data() {
    return {
      documents: 2,
      infoModal: false,
      simulationForm: {
        name: "",
        deliveryDate: "",
        simulationType: "SIMPLE",
        desiredReturnDate: "",
        submissionTechnicalOfferDate: "",
        contractingProjectDate: "",
        workStartDate: "",
        siret: "",
        comment: "",
        agence: null,
        users: null
      },
      previousComponentName: null,
      simpleSimulation: [],
      complexSimulation: [],
      invalidForm: true,
      triggerSave: false,
      USER_TYPE: constants.USER_TYPE,
      ROLE_TYPE: constants.ROLE_TYPE,
    };
  },
  validations: {
    simulationForm: {
      name: {
        required,
      },
      deliveryDate: {
        required,
      },
      simulationType: {
        required,
      },
      desiredReturnDate: {
        required: requiredIf(function() {
          return this.fieldsRequiredB2B2B;
        })
      },
      submissionTechnicalOfferDate: {
        required: requiredIf(function() {
          return this.fieldsRequiredB2B2B;
        })
      },
      contractingProjectDate: {
        required: requiredIf(function() {
          return this.fieldsRequiredB2B2B;
        })
      },
      workStartDate: {
        required: requiredIf(function() {
          return this.fieldsRequiredB2B2B;
        })
      },
      siret: {
        required: requiredIf(function() {
          return this.fieldsRequiredB2B2B;
        }),
        customValidation: function(value) {
          return this.siretIsValid;
        }
      },
      agence: {
        required: requiredIf(function() {
          return this.fieldsRequiredB2B2B;
        })
      },
      users: {
        required
      },
      comment: {
        required
      },
    },
  },
  created() {
    if (this.$route.params["id"]) {
      this.goTo(1)
    }
  },
  mounted() {
    if (this.$route.params["id"]) {
      // case of update
      this.edit = true;
      const id = this.$route.params["id"];
      this.setSimulation({});
      this.fetchSimulation(id);
      this.editSimpleSimulation();
      this.editComplexSimulation();
    } else {
      // case of add
      // this.$store.commit('document/setDocuments', []);
      this.resetSimulationAction();
      this.getSimpleSimulation();
      this.getComplexSimulation();
    }
    eventBus.$on('invalid-form', (invalid) => {
      this.invalidForm = invalid;
    });
    eventBus.$on('save-simulation', (payload) => {
      if (this.triggerSave) {
        this.save(this.previousComponentName, payload);
      }
    });
  },
  computed: {
    ...mapGetters("loader", { loading: "getLoading" }),
    ...mapGetters("simulation", {
      currentStep: "getCurrentStep",
      simulationType: "getSimulationType",
      simulation: "getSimulation",
      isCurrentStep: "isCurrentStep",
      steps: "getSteps",
      componentName: "getComponentName"
    }),
    ...mapGetters('simulation', ['getGoNextStepDisabled']),
    ...mapGetters("document", ["getDocuments", "getDraftDocuments", "getLeavePage", "getDocFormInvalid"]),
    ...mapGetters("account", { userInfo: "getCurrentUser" }),
    isEdit() {
      const isEdit = this.$route.path.includes('/edit');
      this.setIsEdit(isEdit);
      return isEdit;
    },
    isNextFinish() {
      if (!this.isEdit) {
        return (
          (this.currentStep < this.complexSimulation.length - 1 &&
            this.simulationType === "COMPLEX") ||
          (this.currentStep < this.simpleSimulation.length - 1 &&
            this.simulationType !== "COMPLEX")
        );
      } else {
        return (
          (this.currentStep < this.complexSimulation.length - 1 &&
            this.simulationType === "COMPLEX") ||
          (this.currentStep < this.simpleSimulation.length - 1 &&
            this.simulationType !== "COMPLEX")
        );
      }
    },
    showNav() {
          return (this.componentName !== 'FinalStep')
    },
    showNextTerminateButton() {
      return true;
    },
    isInvalid() {
      return (this.invalidForm && (this.isEdit || this.currentStep > 1)) || this.getDocFormInvalid;
    },
    fieldsRequiredB2B2B() {
      return this.userInfo.clientType === this.USER_TYPE.B2B2B;
    },
    simulationTypeSimpleOrPhoenix(){
      return ["SIMPLE", "PHOENIX"].includes(this.simulationType);
    }
  },
  methods: {
    ...mapMutations("simulation", {
      goTo: "goToStep",
      changeStatus: "changeStepStatus",
      setIsEdit: "setIsEdit",
      setSimulation: "setSimulation"
    }),
    ...mapMutations("document", ["setDraftDocuments"]),
    ...mapActions("simulation", [
      "persistSimulationAction",
      "resetSimulationAction",
    ]),
    ...mapActions("document", ["saveDocumentsAction", "editDocumentTypesAction"]),
    goNext() {
      this.previousComponentName = this.componentName;
      this.triggerSave = true;
      if(this.simulationTypeSimpleOrPhoenix && !this.isEdit && this.userInfo.clientType === this.USER_TYPE.B2B2B && this.currentStep === 3){
        this.goTo(this.currentStep + 2)
      }else if(this.simulationTypeSimpleOrPhoenix && !this.isEdit && this.userInfo.clientType !== this.USER_TYPE.B2B2B && this.currentStep === 2){
        this.goTo(this.currentStep + 3)
      }else if(this.simulationTypeSimpleOrPhoenix && this.isEdit && this.userInfo.clientType === this.USER_TYPE.B2B2B && this.currentStep === 2){
        this.goTo(this.currentStep + 2)
      }else if(this.simulationTypeSimpleOrPhoenix && this.isEdit && this.userInfo.clientType !== this.USER_TYPE.B2B2B && this.currentStep === 1){
        this.goTo(this.currentStep + 3)
      }
      else if(this.simulationType === "COMPLEX" && !this.isEdit && this.userInfo.clientType !== this.USER_TYPE.B2B2B && this.currentStep === 2){
        this.goTo(this.currentStep + 2)
      }
      else if(this.simulationType === "COMPLEX" && this.isEdit && this.userInfo.clientType !== this.USER_TYPE.B2B2B && this.currentStep === 1){
        this.goTo(this.currentStep + 2)

      }else{
        this.goTo(this.currentStep + 1);
      }
      this.changeStatus({ key: this.currentStep, status: "CURRENT" });
      this.changeStatus({ key: this.currentStep - 1, status: "DONE" });
    },
    goPrev() {
      if (this.userInfo.clientType !== this.USER_TYPE.B2B2B && this.simulationTypeSimpleOrPhoenix && ((this.currentStep === 5 && !this.isEdit) || (this.currentStep === 4 && this.isEdit))) {
        this.goTo(this.currentStep - 3);
      }
      else if (this.userInfo.clientType === this.USER_TYPE.B2B2B && this.simulationTypeSimpleOrPhoenix && ((this.currentStep === 5 && !this.isEdit) || (this.currentStep === 4 && this.isEdit))) {
        this.goTo(this.currentStep - 2);
      }
      else if (this.userInfo.clientType !== this.USER_TYPE.B2B2B && this.simulationType === "COMPLEX" && ((this.currentStep === 4  && !this.isEdit) || (this.currentStep === 3 && this.isEdit))) {
        this.goTo(this.currentStep - 2);
      }
      else {
        this.goTo(this.currentStep - 1);
        if(this.userInfo.clientType === this.USER_TYPE.B2B2B && this.isEdit && this.currentStep === 1){
          if (this.$route.params["id"]) {
            // case of update
            this.edit = true;
            const id = this.$route.params["id"];
            this.setSimulation({});
            this.fetchSimulation(id);
            this.editSimpleSimulation();
            this.editComplexSimulation();
          }
        }
      }
      if((!this.isEdit && this.currentStep === 2) && this.simulation.id) {
        this.edit = false;
        const id = this.simulation.id;
        this.fetchSimulation(id);
      }
    },
    // simulation simple
    getSimpleSimulation() {
      this.simpleSimulation = (this.userInfo.clientType === this.USER_TYPE.B2B2B)
          ? this.steps.filter((item) => item.key !== 4)
          : this.steps.filter((item) => item.key !== 3 && item.key !== 4);
    },
    editSimpleSimulation() {
      this.simpleSimulation = (this.userInfo.clientType === this.USER_TYPE.B2B2B)
          ? this.steps.filter((item) => item.key !== 1 && item.key !== 4)
          : this.steps.filter((item) => item.key !== 1 && item.key !== 3 && item.key !== 4);
    },

    // simulation complex
    getComplexSimulation() {
      this.complexSimulation = (this.userInfo.clientType === this.USER_TYPE.B2B2B)
          ? this.steps
          : this.steps.filter((item) => item.key !== 3);
    },
    editComplexSimulation() {
      this.complexSimulation = (this.userInfo.clientType === this.USER_TYPE.B2B2B)
          ? this.steps.filter((item) => item.key !== 1)
          : this.steps.filter((item) => item.key !== 1 && item.key !== 3);
    },

    fetchSimulation(id) {
      workService.fetchWorkById(id).then(
        (result) => {
          result.deliveryDate = result.deliveryDate> 0 ? moment(result.deliveryDate, "x").format("YYYY-MM-DD") : null;
          result.desiredReturnDate = result.desiredReturnDate > 0 ? moment(result.desiredReturnDate, "x").format("YYYY-MM-DD") : null;
          result.submissionTechnicalOfferDate = result.submissionTechnicalOfferDate > 0 ? moment(result.submissionTechnicalOfferDate, "x").format("YYYY-MM-DD") : null;
          result.contractingProjectDate = result.contractingProjectDate > 0 ? moment(result.contractingProjectDate, "x").format("YYYY-MM-DD") : null;
          result.workStartDate = result.workStartDate> 0 ? moment(result.workStartDate, "x").format("YYYY-MM-DD") : null;
          result.agence = result.users?.[0]?.agence;
          if(this.userInfo.clientType === this.USER_TYPE.B2B2B && result.agence) {
            this.$store.dispatch("simulation/fetchUsersByAgency", result.agence);
          }
          this.$store.commit("simulation/setSimulation", result);
          if (this.isEdit) {
            this.$store.commit(
              "simulation/setSimulationType",
              result.simulationType
            );
            this.$store.commit("simulation/goToStep", 1);
          } else if(result.simulationType !== "COMPLEX") {
            this.$store.commit(
              "simulation/setSimulationType",
              'SIMPLE'
            );
          }
          this.simulationForm = {
            name: result.name,
            deliveryDate: moment(result.deliveryDate, "x").format("YYYY-MM-DD"),
            simulationType: result.simulationType,
            desiredReturnDate: moment(result.desiredReturnDate, "x").format("YYYY-MM-DD"),
            submissionTechnicalOfferDate: moment(result.submissionTechnicalOfferDate, "x").format("YYYY-MM-DD"),
            contractingProjectDate: moment(result.contractingProjectDate, "x").format("YYYY-MM-DD"),
            workStartDate: moment(result.workStartDate, "x").format("YYYY-MM-DD"),
            siret: result.siret,
            comment: result.comment,
            agence: result.users?.[0]?.agence,
            users: result.users,
          };
        },
        (error) => {
          console.log(error);
        }
      );
    },
    save(componentName, payload) {
      switch (componentName) {
        case 'SimulationInfos':
          this.persistSimulation(payload);
          break;
        case 'SimulationPlanning':
          this.persistDatesSimulation(payload);
          break;
        case 'SimulationOperations':
          this.persistEstimations(payload);
          break;
        case 'SimulationDocuments':
          this.saveDraftDocuments();
          this.editDocumentType();
          break;
      }
      this.triggerSave = false;
    },
    persistSimulation(simulation){
      if (!simulation.type) {
        simulation.type = this.simulationType;
      }
      this.persistSimulationAction({simulation: simulation});
    },
    saveDraftDocuments() {
      const drafts = this.getDraftDocuments;
      if (drafts && drafts.length > 0) {
        drafts.forEach((document) => {
          document.types = map(document.types, "code");
        });
        this.setDraftDocuments(null);
        this.saveDocumentsAction({
          id: parseInt(this.$route.params["id"], 10),
          customFilter: this.customFilter,
          documentsToUpload: drafts
        });
      }
    },
    editDocumentType() {
      const editedDocs = [];
      this.getDocuments.forEach((document) => {
        const types = document.types;
        editedDocs.push({ id: document.id, types: map(types, "code") });
      });
      this.editDocumentTypesAction({ documents: editedDocs });
    },
    persistEstimations(payload) {
      estimationService.persistEstimations(
          payload.tabsAddress,
          payload.tabsFiche,
          payload.id
      );
    },
    persistDatesSimulation(payload){
      simulationService.persistDatesSimulation(payload);
    },
    saveComment(payload){
      simulationService.saveComment(
          payload.id,
          payload.comment,
      )
    },
  },
  beforeRouteLeave (to, from, next) {
    if (this.getDraftDocuments && this.getDraftDocuments.length > 0) {
      this.$root.$emit("bv::show::modal", 'modal-confirm_leave');
      this.$root.$on('bv::modal::hidden', () => {
        this.getLeavePage ? next() : next(false);
      });
    } else {
      next();
    }
  },
  beforeDestroy() {
    eventBus.$off('invalid-form');
    eventBus.$off('save-simulation');
  }
};
</script>
