var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"Simulator"}},[_c('div',[(
        _vm.simulationForm.simulationType === 'COMPLEX' ||
        _vm.simulationType === 'COMPLEX'
      )?_c('ul',{staticClass:"gigwhat-navTabs"},[_vm._l((_vm.complexSimulation),function(step,index){return [_c('li',{key:index,staticClass:"navTab_item",class:[
            (_vm.isEdit && _vm.currentStep === step.key - 1) || (!_vm.isEdit && _vm.currentStep === step.key) ? 'active-navTab' : '',
            (_vm.isEdit && _vm.currentStep > step.key - 1) || (!_vm.isEdit && _vm.currentStep > step.key) ? 'done-stepTab' : '' ]},[_c('button',{attrs:{"id":'step-'+step.key}},[((_vm.isEdit && _vm.currentStep > step.key - 1) || (!_vm.isEdit && _vm.currentStep > step.key))?_c('span',{staticClass:"navTab-item_done"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"10.782","height":"7.769","viewBox":"0 0 10.782 7.769"}},[_c('path',{attrs:{"id":"Tracé_2381","data-name":"Tracé 2381","d":"M67.246,104.023a1.113,1.113,0,0,0,.79-.327l5.534-5.534a1.117,1.117,0,0,0-1.58-1.58l-4.744,4.744L65.023,99.1a1.117,1.117,0,1,0-1.58,1.58l3.013,3.013a1.114,1.114,0,0,0,.79.327Z","transform":"translate(-63.115 -96.254)","fill":"#808fa3"}})])]):_c('span',{staticClass:"navTab-item_number"},[_vm._v(_vm._s(index + 1))]),_c('span',{staticClass:"navTab-item_label"},[_vm._v(_vm._s(step.name))]),_c('b-tooltip',{attrs:{"target":'step-'+step.key,"placement":"bottom","triggers":"focus"}},[_vm._v(" "+_vm._s(step.name)+" ")])],1)])]})],2):_c('ul',{staticClass:"gigwhat-navTabs"},[_vm._l((_vm.simpleSimulation),function(step,index){return [_c('li',{key:index,staticClass:"navTab_item",class:[
            (_vm.isEdit && _vm.currentStep === step.key - 1) || (!_vm.isEdit && _vm.currentStep === step.key) ? 'active-navTab' : '',
            (_vm.isEdit && _vm.currentStep > step.key - 1) || (!_vm.isEdit && _vm.currentStep > step.key) ? 'done-stepTab' : '' ]},[_c('button',{attrs:{"id":'step-'+step.key}},[((_vm.isEdit && _vm.currentStep > step.key - 1) || (!_vm.isEdit && _vm.currentStep > step.key))?_c('span',{staticClass:"navTab-item_done"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"10.782","height":"7.769","viewBox":"0 0 10.782 7.769"}},[_c('path',{attrs:{"id":"Tracé_2381","data-name":"Tracé 2381","d":"M67.246,104.023a1.113,1.113,0,0,0,.79-.327l5.534-5.534a1.117,1.117,0,0,0-1.58-1.58l-4.744,4.744L65.023,99.1a1.117,1.117,0,1,0-1.58,1.58l3.013,3.013a1.114,1.114,0,0,0,.79.327Z","transform":"translate(-63.115 -96.254)","fill":"#808fa3"}})])]):_c('span',{staticClass:"navTab-item_number"},[_vm._v(_vm._s(index + 1))]),_c('span',{staticClass:"navTab-item_label"},[_vm._v(_vm._s(step.name))]),_c('b-tooltip',{attrs:{"target":'step-'+step.key,"placement":"bottom","triggers":"focus"}},[_vm._v(" "+_vm._s(step.name)+" ")])],1)])]})],2),(_vm.isEdit)?_c('simulation-wizzard',{attrs:{"currentStep":_vm.currentStep + 1}}):_c('simulation-wizzard',{attrs:{"currentStep":_vm.currentStep}}),(_vm.showNav)?_c('div',{staticClass:"\n        giga-simulator_footer\n        giga-document_footer\n        giga-flex giga-flex_end\n      "},[(_vm.currentStep > 1 && _vm.currentStep !== _vm.steps.length)?_c('div',{staticClass:"giga-button"},[_c('button',{staticClass:"giga-blue_outlined_button no-icon",on:{"click":_vm.goPrev}},[_c('span',[_vm._v("Précédent")])])]):_vm._e(),_c('div',{staticClass:"giga-button"},[_c('button',{staticClass:"giga-green_button no-icon",attrs:{"disabled":_vm.isInvalid},on:{"click":_vm.goNext}},[(_vm.isNextFinish)?_c('span',[_vm._v("Suivant")]):_c('span',[_vm._v("Suivant")])])])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }